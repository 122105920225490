import React, { useEffect } from 'react'
import ExhibitionsListItem from './ExhibitionsListItem'
import { scrollHandler } from '../../utils/functions'

type Props = {
  items: any
}

const ExhibitionsList: React.FunctionComponent<Props> = ({ items }) => {

  useEffect(() => {
    scrollHandler();
  }, [])

 
  return (<div className="page-contents">
    <div className="boxes">
      {items.allWordpressWpExhibitions.edges.map(item => (
        <div className="box" key={item.node.wordpress_id}>
          <ExhibitionsListItem item={item} />
        </div>
      ))}
    </div>
  </div>)
}

export default ExhibitionsList
