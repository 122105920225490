export const scrollHandler = () => {

  let pages = document.querySelectorAll('.page')
  let ticking = false
  let active = 0
  let pageTitle = document.querySelectorAll('.page-title')
  let caption = document.querySelectorAll('.caption')

  function func() {

    if (!ticking) {
      requestAnimationFrame(function() {
        ticking = false;

        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
                  
        for(var i = 0; i < pages.length; i++) {

          // get image offset top
          let image = pages[i].querySelectorAll('.inner')

          if(image.length){
            let imageRect = image[0].getBoundingClientRect()
            let imageY = imageRect.top + scrollTop
            
            if(scrollTop + window.innerHeight > document.body.clientHeight - 80){                  
              caption[caption.length-1].classList.add('hidden')
            } else {
              caption[caption.length-1].classList.remove('hidden')
            }

            if(imageY - window.innerHeight < scrollTop){
              active = i
            }

          }

        }

        for(var i = 0; i < pages.length; i++) {
          pages[i].classList.remove('active')
        }

        if(pages[active])
        pages[active].classList.add('active')

        active = 0

        if(scrollTop > 40){                  
          pageTitle[0].classList.add('hidden')
        } else {
          pageTitle[0].classList.remove('hidden')
        }

      })

      ticking = true
      
    }
  }


  window.addEventListener('scroll', func, {passive: true})

}