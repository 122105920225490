import * as React from 'react'
import Layout from '../../components/layout'
import List from '../../components/exhibitions/ExhibitionsList'
import { Works } from '../../interface'
import { graphql } from 'gatsby'

type Props = {
  items: Works[]
  pathname: string,
  data: any
}

class IndexPage extends React.Component<Props> {

  render() {
    //onsole.log(this.props)
    return <Layout title="Exhibitions">
    <h1 className="page-title">Exhibitions</h1>
    <div className="page-contents">
      <section className="works-section">
        <div className="container">
          <List items={this.props.data} />
        </div>
      </section>
    </div>
  </Layout>
  }
}

export default IndexPage


export const query = graphql`
      query {
        __typename
        allWordpressWpExhibitions {
          edges {
            node {
              id
              wordpress_id
              title
              slug
              featured_image_src
              acf {
                subtitle
                works_overview
                works_images {
                  works_images_image {
                    url
                  }
                  works_images_image_caption
                  works_images_image_number_of_columns
                }
              }
            }
          } 
        }       
      }

    `;