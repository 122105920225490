import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Works } from '../../interface'
import { Link } from "gatsby"


class ExhibitionsListItem extends React.Component<{item?:Works}> {

	works:any = {};

  constructor(props:any) {
    super(props);
    
    if(this.props.item)
    this.works = this.props.item;
	
  }

	render(){

    let subtitle = this.works.node.acf.subtitle ? '<br />' + this.works.node.acf.subtitle : ''
    let url = '/exhibitions/' + this.works.node.slug + '/'
    let featuredImage = this.works.node.featured_image_src.replace('/yjrimsk/image/upload/', '/yjrimsk/image/upload/q_auto/')
    let worksOverview = this.works.node.acf.works_overview ? this.works.node.acf.works_overview : '';

    if(this.works.node.acf.works_images || this.works.node.content){
      return (  
        <Link to={url}>
          <img src={featuredImage} alt=""/>
            <p className="post-title">
              {this.works.node.title}{ReactHtmlParser(subtitle)}
            </p>
            <div className="post-content" dangerouslySetInnerHTML={{ __html: worksOverview }} />
        </Link>
      )
    } else {
      return (  
        <div>
          <img src={featuredImage} alt=""/>
          <p className="post-title">
            {this.works.node.title}{ReactHtmlParser(subtitle)}
          </p>
          <div className="post-content" dangerouslySetInnerHTML={{ __html: worksOverview }} />
        </div>
      )
    }
  }

}


export default ExhibitionsListItem